.cardContainer{
    display: flex;
    width: 100%;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1.25rem;
    background: #FFF;
    gap: 1rem;
    border: 1px solid #EAECF0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.divider{
    height: 0.0625rem;
    align-self: stretch;
    background-color: #EAECF0;
}