.machineTable{
    width: 100%;
}
.machineTableHeader{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    /*justify-content: space-between;*/
    text-align: left;
    font-family: Inter, serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    color: #667085;
}
.machineLeftFields{
    display: flex;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    padding: .75rem 0;
}
.machineRightFields{
    display: flex;
    text-align: left;
    width: 3rem;
    padding: .75rem 1.5rem;
}
.machineTableRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem;
    /*justify-content: space-between;*/
    text-align: left;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: #667085;
}
.user{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    font-weight: 500;
    color: #101828;
    width: 10rem;
    flex: 1 0 0
}
.modules{
    display: flex;
    text-align: left;
    width: 12rem;
}
.machineId{
    display: flex;
    align-self: stretch;
    width: 10rem;
    flex: 1 0 0
}
.machineName{
    display: flex;
    align-self: stretch;
    width: 10rem;
    flex: 1 0 0
}
.version{
    display: flex;
    text-align: left;
    width: 5rem;
}
.date{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 7rem;
}
.actions{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 3rem;
}
