.app-container {
    display: flex;
    transition: margin-left 0.3s ease; /* Add a smooth transition effect */
}
.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 15rem;
    top: 0;
    left: 0;
    background-color: #eee;
    position: fixed;
    height: 100%;
}
.sidebarElements{
    display: flex;
    padding-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.sidebarBottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 1rem 1rem;
}
.menuItem{
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0.75rem;
    align-items: flex-start;
    gap: 1rem;
    color: #000;
    align-self: stretch;
    cursor: pointer;
    text-decoration: none;
}
.menuButton{
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0.75rem;
    align-items: center;
    background-color: #C9C5CA;
    gap: 1rem;
    color: #000;
    align-self: stretch;
    cursor: pointer;
    border: none;
    text-decoration: none;
}
.sidebarElement{
    display: flex;
    padding: 0;
    align-items: flex-start;
    }
.userBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}
.userBlock img{
    width: 6rem;
    height: 6rem;
    border-radius: 12.5rem;
    border: 4px solid #FFF;
    /*background: var(--Avatar-user-square-Candice-Wu, url(<path-to-image>), lightgray 50% / cover no-repeat);*/
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.userBlock.username{
    align-self: stretch;
    color: #101828;
    text-align: center;
    font-family: Inter, serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
}
.userBlock.roleText{
    align-self: stretch;
    color: #667085;
    text-align: center;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.email{
    color: #667085;
    text-align: center;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.status{
    display: flex;
    flex-direction: row;
    padding: 0 1.5rem;
    align-items: flex-start;
    text-align: center;
    justify-content: space-between;
    gap: 0.5rem;
}
.statusLabel{
    color: #667085;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.statusValue{
    color: #6941C6;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
/*.main-content {*/
/*    margin-left: 200px; !* Default width when not collapsed *!*/
/*    padding: 20px;*/
/*}*/

.app-container.collapsed .main-content {
    margin-left: 0; /* Adjust this margin to be 0 when the sidebar is collapsed */
}

.logoBlock{
    display: flex;
    align-items: center;
    padding: 0 2rem;
    gap: 0.75rem;
    color: #53389E;
    background-color: #eee;
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    border-top-color: #ffffff;
}
.logo{
    width: 1.5rem;
    height: 1.5rem;
}

.menu-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use the default text color */
    display: flex;
    align-items: center; /* Align icon and label vertically */
    padding: 8px; /* Adjust padding as needed */
}

.menu-link:hover {
    background-color: #f0f0f0; /* Add a background color on hover if desired */
}