.prospectiveCustomerDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 1rem;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
    margin: 1.5rem 0;
}
.pcdHeaderSection{
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.pcdTitleBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
    flex: 1 0 0;
}
.pcdTitleIconContainer{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
}
.pcdTitleIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.pcdTitleTextContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
}
.pcdTitleText{
    align-self: stretch;
    color: #101828;
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.pcdCloseButtonContainer{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.pcdContentSection{
    display: flex;
    height: 27.5rem;
    align-items: flex-start;
    align-self: stretch;
}
.pcdCustomerSection{
    display: flex;
    width: 45rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}
.pcdCustomerContent{
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    background: var(--Base-White, #FFF);
}
.pcdCustomerRow{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}
.pcdInputFieldSection{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
}
.pcdInputFieldBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
    flex: 1 0 0;
    flex-shrink: 0;
}
.pcdInputFieldLabel{
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.pcdCustomerFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Base-White, #FFF);
}
.pcdCustomerFooterBlock{
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}
.pcdNotesSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}
.pcdMessagesBlock{
    display: flex;
    padding: 1.25rem 0;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
    height: 15.625rem;
    overflow-y: auto ;
}
.pcdMessages{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    flex: 1 0 0;
}
.pcdMessage{
    display: flex;
    padding: 0 1rem;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.pcdMessageIcon{
    width: 1.5rem;
    height: 1.5rem;
}
.pcdMessageTextBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1 0 0;
}
.pcdMessageTimestamp{
    align-self: stretch;
    color: var(--Gray-500, #667085);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.pcdMessageText{
    align-self: stretch;
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.pcdNoteSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}
.pcdNoteBlock{
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
}
.pcdTextarea{
    display: flex;
    height: 6.5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.pcdNoteFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.pcdButton{
    display: flex;
    align-items: flex-start;
}