.addLicenses{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position:fixed;
    top: 50%;
    left: 50%;
    width: 30rem;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #FFFFFF;
}
.adFormContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    /*padding: 1.5rem;*/
    align-items: flex-start;
    align-self: stretch;
}
.adTitleContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1rem;
}
.iconContainer{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    border: 8px solid #F9F5FF;
    background: #F4EBFF;
}
svg{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    color: #7F56D9;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;

    color: #101828;
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.actionsContainer{
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    gap: 2rem;
}
.cancelButton{
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.confirmButton{
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid #7F56D9;
    background: #7F56D9;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.adLicenseRows{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 28rem;
    gap: 1rem;
    padding: 1rem;
}
.adLicenseRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
}
.adName{
    color: #101828;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.adDataBlock{
    display: flex;
    align-items: center;
    gap: 1.25rem;
}
.code{
    display: flex;
    padding: 0.125rem 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: #F9F5FF;
    color: #6941C6;
    text-align: center;
    font-family: Inter, serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
}
.adLicenseCountContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.adLicenseCountContainer .reduce{
    display: flex;
    height: 1.5rem;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0 0 0.5rem;
    border: 1px solid #D0D5DD;
    background: #FFF;
}
.reduce svg{
    width: 1.25rem;
    height: 1.25rem;
}
.adLicenseCountContainer .count{
    display: flex;
    padding: 0.575rem 0.875rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    background: #FFF;
    color: #101828;
    text-align: center;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.adLicenseCountContainer .increase{
    display: flex;
    height: 1.5rem;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.5rem 0.5rem 0;
    border: 1px solid #D0D5DD;
    background: #FFF;
}
.adDays{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
}
