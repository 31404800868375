.machineDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 25rem;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 1.5rem;
    gap: 1rem;
    background: var(--Base-White, #FFF);
    border-radius: 0.75rem;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
}