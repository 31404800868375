.taBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: 1 0 0;
    align-self: stretch;
}
.taLabel{
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
}
.taInputBlock{
    display: flex;
    padding: 0.625rem 0.875rem;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.taInput{
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.taInput textarea{
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    /*overflow: hidden;*/
}
