.updateUser{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position:fixed;
    top: 50%;
    left: 50%;
    width: 30rem;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #FFFFFF;
}
.uuFormContainer{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.5rem;
    gap: 1.5rem;
    width: 100%;
}
.uuTitleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.uuTitleBlock{
    display: flex;
    align-items: center;
    gap:1rem
}
.uuInputBlock{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
}
.uuInputLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
    width: 12rem;
    text-wrap: nowrap;
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.uuNote{
    color: var(--error-700);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}