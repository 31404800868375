.rtContainer{
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}
.rtTableContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);

}
.react-table{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    background: var(--Base-White, #FFF);

}
.rtTitleBlock{
    display: flex;
    height: 2.75rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.rtTitle{
    color: #101828;
    font-family: Inter, serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
}
.rtAdhocBlock{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    height: 2.75rem;
    align-items: center;
    align-self: stretch;
}
.rtSearchBox{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 10rem;
    height: 2.75rem;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.rtSearchBox .rtInput{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex-shrink: 0;
}
.rtHeader{
    background-color: #FFF;
}
.rtHeaderCell{
    padding: 0.5rem 1.5rem;
    text-align: left;
    color: #667085;
    font-family: Inter, serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
}

.rtDataRow{
    margin: 1rem;
}
.rtDataCell{
    padding: 0.5rem 1.5rem;
    text-align: left;
    color: #667085;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

/* CSS for striped table */
table {
    width: 100%;
    border-collapse: collapse;
}

/* Style for even rows */
.rtDataRow:nth-child(even) {
    background-color: #FFFFFF; /* or any other color you prefer */
}

/* Style for odd rows (optional, as the default table background will be used) */
.rtDataRow:nth-child(odd) {
    background-color: #f2f2f2; /* or any other color you prefer */
}

/* Optional hover effect */
.rtDataRow:hover {
    background-color: #d4d4d4; /* or any other color you prefer */
}
.rtPagination{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.rtPaginationButtons{
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.rtButtonSet{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.rtPaginationPageDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.rtPaginationPageDetailsValue{
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.rtInput{
    width: 5rem;
}

/*.rtInput input::-webkit-outer-spin-button,*/
/*input::-webkit-inner-spin-button {*/
/*    -moz-appearance: textfield;*/
/*    -webkit-appearance: none;*/
/*    margin: 0;*/
/*}*/
