.forgotPasswordModal{
    display: flex;
    width: 25rem;
    padding: 1.5rem;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    align-items: center;
    gap: 2rem;
    border-radius: 0.75rem;
    background: var(--Base-White, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.fpContentSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.fpIconBlock{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    border: 8px solid var(--Primary-50, #F9F5FF);
    background: var(--Primary-100, #F4EBFF);
}
.fpIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.fpTextBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}
.fpTitleText{
    align-self: stretch;
    color: var(--Gray-900, #101828);
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.fpStepText{
    align-self: stretch;
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.fpDescription{
    align-self: stretch;
    color: var(--Gray-500, #667085);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.fpFooter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.fpFooterContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.fpFooterActions{
    display: flex;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
}
