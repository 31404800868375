.parentGridRowContainer {
}

.gridRowContainer{
}

.gridRow{
    display: flex;
    flex-direction: row;
    /*height: 3rem;*/
    padding: 0.5rem 0;
    align-items: flex-start;
    /*justify-content: space-between;*/
}

.labelColumn{
    width: 9.875rem;
}

.dataColumn{
    display: flex;
    flex-direction: column;
}

.licenseRow{
    display: flex;
    /*flex-direction: row;*/
    width: 100%;
    padding: 0.5rem 1.5rem;
    align-items: flex-start;
    justify-content: space-between;
}

.licenseLabel{
    flex-grow: 1;
}

.licenseData{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

}

.licenseDataColumn{
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: flex-start;
}

.element {
    flex-basis: auto;
    background: lightblue;
    text-align: center;
}
.passwordReset{
    display: flex;
    height: 3rem;
    padding: 1rem 0;
    gap: 1.5rem;
    align-items: center;
    align-self: stretch;
}
.passwordResetLink{
    display: flex;
    align-items: flex-start;
    color: var(--Primary-700, #6941C6);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.passwordResetIcon{
    display: flex;
    align-items: flex-start;
}

