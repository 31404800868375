.defaultText {
    font-family: Inter, serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #667085;
}

.cardTitleText {
    font-family: Inter, serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #101828;
}

.pageTitleText {
    font-family: Inter, serif;
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 2.375rem;
    color: #101828;
}

.labelText {
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #344054;
}

.dataText {
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #667085;
}

.smallColumnTitleText {
    font-family: Inter, serif;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #667085;
}


.messageText {
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #667085;
}

.errorMessageText {
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #F04438;
}

