.selectBlock {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Gray-50, #F9FAFB);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.selectedText {
    display: flex;
    padding: 0.625rem 0rem 0.625rem 0.875rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
}

.selectOptionsList {
    display: flex;
    padding: 0.625rem 0rem 0.625rem 0.875rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
}

.optionTextBlock {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
}

.optionText {
    color: var(--Gray-500, #667085);
    /* Text M/Normal */
    flex: 1 0 0;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.custom-select {
    display: flex;
    align-self: stretch;
    position: relative;
}

select{
    appearance: none;
    width: 100%;
    padding: 0.675em 6em 0.675em 1em;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    border: 1px solid #caced1;
    border-radius: 0.25rem;
    color: #000;
    cursor: pointer;

}

.custom-select::before,
.custom-select::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 1rem;
    pointer-events: none;
}

.custom-select::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
}

.custom-select::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
}
