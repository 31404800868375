.dashboard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.licenseButtons{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}