.licenseDataBlock{
    width: 100%;
}

.licenseRow{
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0 ;
    justify-content: space-between;
    align-items: center;
}

.licenseValueBlock{
    display: flex;
    /*width: 20rem;*/
    /*padding-left: 0.5rem;*/
}

.licenseValue{
    display: flex;
    padding: 0.5rem 0.5rem;
    justify-content: flex-end;
    align-items: flex-start;
    width: 4rem;
}

.licenseLabel{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
}