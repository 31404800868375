.adminDashboard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 1.5rem 0;
    width: 100%;
    /*height: 100%;*/
    /*min-height: 20rem;*/
    background-color: #F9F5FF;
    color: black;
    font-family: Inter, serif;
    font-style: normal;
}
.dashboardCards{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    gap: 1.5rem;
}
.dashboardCard{
    display: flex;
    padding: 1.5rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 2px solid var(--Primary-400, #B692F6);
    background: #FFF;
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.10);
    font-family: Inter, serif;
    font-style: normal;
}
.cardTitleBlock{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
}
.cardTitle{
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
}

.cardValue{
    color: var(--Gray-900, #101828);
    text-align: right;
    font-family: Inter, serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;
}

