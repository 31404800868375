.createCompanyForm{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
}
.ccfHeaderSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.ccfHeaderBlock{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
}
.ccfIconBlock{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    border: 8px solid var(--Primary-50, #F9F5FF);
    background: var(--Primary-100, #F4EBFF);
}
.ccfIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.ccfTitleBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
}
.ccfTitle{
    align-self: stretch;
    color: var(--Gray-900, #101828);
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.ccfContentSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.ccfContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
}
.ccfFormBlock{
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
}
.ccfForm{
    display: flex;
    width: 52.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
}
.ccfFormRow{
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.ccfInputSection{
    display: flex;
    height: 4.375rem;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.ccfInputBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
}
.ccfInputLabel{
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.ccfCombineInputs{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
}
.ccfInputSectionRow{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    flex: 1 0 0;
    align-self: stretch;
}
.ccfInputBlockRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}
.ccfInputBlockRowInput{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
}
.ccfInputValueText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
}
.ccfEnabledText{
    color: var(--Success-700, #027A48);
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}
.ccfDisabledText{
    color: var(--Error-700, #B42318);
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}
.ccfCount{
    display: flex;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    gap: 0.5rem;
    padding: 0.575rem 2.5rem;
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    background: #FFF;
    color: #101828;
    text-align: center;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.ccfFooter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.ccfFooterContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.ccfFooterActionsBlock{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
}
.ccfButtonWrapper{
    display: flex;
    align-items: flex-start;
}
