body {
    font-family: Arial, sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 300px;
}
.input-container {
    position: relative;
    display: flex;
    width: 100%;
}
.start-icon-container {
    position: absolute;
    display: flex;
    left: 10px; /* Adjust the left position as needed */
    top: 50%;
    transform: translateY(-50%);
    align-items: flex-start;
    color: #555; /* Adjust the color of the icon */
}
.end-icon-container {
    position: absolute;
    right: 10px; /* Adjust the left position as needed */
    top: 50%;
    transform: translateY(-50%);
    color: #555; /* Adjust the color of the icon */
    /*z-index: 2;*/
}
.custom-input {
    width: 100%;
    height: 44px;
    border: 2px solid #d0d5dd; /* Border color */
    border-radius: 5px;
    padding: 10px;
    /*padding-left: 30px;*/
    box-sizing: border-box;
    font-size: 16px;
    color: #101828; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default focus outline */
}
.custom-input:hover {
    border-color: #101828;
    background-color: #ffffff;
    color: #101828;
}
.custom-input:focus {
    border-color: #7f56d9;
    background-color: #ffffff;
    color: #101828;
}
.custom-input:disabled {
    border-color: #d0d5dd;
    background-color: #f9fafb;
    color: #667085;
}

.custom-input.with-start-adornment {
    padding-left: 3rem;
}
.custom-input.with-end-adornment {
    padding-right: 3rem;
}
