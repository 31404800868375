.changePassword{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 25rem;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 1.5rem;
    gap: 1rem;
    background: var(--Base-White, #FFF);
    border-radius: 0.75rem;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.cpTopSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.cpTitleSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.cpTitleBlock{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
}
.cpIconBlock{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
}
.cpIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.cpTitleText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    color: var(--Gray-900, #101828);
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
}
.cpText{
    align-self: stretch;
}
.cpFormSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.cpInputSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.cpInputBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
}
.cpInputLabel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.cpFooterSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.cpFooterContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.cpFooterActions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
}
.cpFooterActionButton{
    display: flex;
    align-items: center;
}