@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.app,
.content {
  flex: 1;
  height: 100%;
  width: 100%;
  font-family: Inter, serif;
  background: #F9F5FF;
  /*background: #F9F5FF;*/

}

.app {
  display: flex;
  /*position: relative;*/
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#nested-list-subheader{
  background-color: inherit;
}

.password-field input[type='password']::-ms-reveal,
.password-field input[type='password']::-ms-clear {
  display: none;
}

.modal{
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999;
}

.modal.active,
.overlay.active {
  display: flex;
}

:root{
  --grey-25: #fcfcfd;
  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --grey-300: #d0d5dd;
  --grey-400: #98a2b3;
  --grey-500: #667085;
  --grey-600: #475467;
  --grey-700: #344054;
  --grey-800: #1d2939;
  --grey-900: #101828;

  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;

  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;

  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fafd;
  --success-200: #a6f4C5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;

  --primary-900 : #000000;
  --primary-800: #21005D;
  --primary-700: #381E72;
  --primary-600: #4F378B;
  --primary-500: #7F56D9;
  --primary-400: #7F67BE;
  --primary-300: #9A82DB;
  --primary-200: #B69DF8;
  --primary-100: #D0BCFF;
  --primary-50: #EADDFF;
  --primary-25: #F6EDFF;

  --secondary-900: #000000;
  --secondary-800: #1D192B;
  --secondary-700: #332D41;
  --secondary-600: #4A4458;
  --secondary-500: #625B71;
  --secondary-400: #7A7289;
  --secondary-300: #958DA5;
  --secondary-200: #B0A7C0;
  --secondary-100: #CCC2DC;
  --secondary-50: #E8DEF8;
  --secondary-25: #F6EDFF;

  --tertiary-900: #000000;
  --tertiary-800: #31111D;
  --tertiary-700: #492532;
  --tertiary-600: #633B48;
  --tertiary-500: #7D5260;
  --tertiary-400: #986977;
  --tertiary-300: #B58392;
  --tertiary-200: #D29DAC;
  --tertiary-100: #EFB8C8;
  --tertiary-50: #FFD8E4;
  --tertiary-25: #FFECF1;

  --neutral-900: #000000;
  --neutral-80: #1C1B1F;
  --neutral-700: #313033;
  --neutral-600: #484649;
  --neutral-500: #605D62;
  --neutral-400: #787579;
  --neutral-300: #939094;
  --neutral-200: #AEAAAE;
  --neutral-100: #C9C5CA;
  --neutral-50: #E6E1E5;
  --neutral-25: #FFFBFE;

}


