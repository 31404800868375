.loginPage{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    align-items: center;
    align-self: stretch;
}
.loginContainer{
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    align-self: stretch;
}
.login{
    display: flex;
    width: 22.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}
.titleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
}
.title{
    align-self: stretch;
    color: #101828;
    font-family: Inter, serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;
}
.description{
    color: #667085;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.fieldContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.375rem;
    align-self: stretch;
}
.label{
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.inputField{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 0.875rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.inputField input{
    border:none;
    outline:none;
    color: #667085;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    flex: 1 0 0;
}
.forgotPasswordContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.forgotPasswordContainer .link{
    color: #6941C6;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.forgotPasswordContainer .messageText{
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.loginButton{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: 0.625rem 1.125rem;
    justify-content: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid #7F56D9;
    background: #7F56D9;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.footer{
    display: flex;
    height: 6rem;
    padding: 2rem;
    align-items: flex-end;
    align-self: stretch;
}

.leftSide {
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    align-self: stretch;
}

.rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%; /* Ensure the image takes up the full width */
    align-self: stretch;
}


