.machineActionsModal{
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40rem;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 0.75rem;
    background: #FFF;
    border: 2px solid #667085;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.modalTitleBlock{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
    color: #101828;
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.modalIcon{
    color: #7F56D9;
    border-radius: 1.75rem;
    border: 8px solid #F9F5FF;
    background: #F4EBFF;
}
.actionBlock{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /*justify-content: space-between;*/
    width: 100%;
    gap: 1.25rem;
}
.blockTitle{
    display: flex;
    width: 9.375rem;
    flex-direction: column;
    align-items: flex-start;
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.actionOptions{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex: 1 0 0;
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.reasonOptions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    flex: 1 0 0;
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.fileUploadBlock{
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
    color: #344054;
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.fileUpload{
    display: flex;
    height: 6.75rem;
    padding: 1rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #939496;
    background: #FFF;
}
