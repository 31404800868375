.button{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.buttonIcon{
    display: flex;
    align-items: flex-start;
}
.secondary{
    background-color: #FFFFFF;
    color: #344054;
    border-color: #d0d5dd;
}
.secondary:hover{
    background-color: #f9fafb;
}

.medium{
    padding: 0.625rem 1rem ;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.disabled{
    border-radius: 0.5rem;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.buttonAdornment{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}