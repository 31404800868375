.editProfile{
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex: 1 0 0;
    background: var(--Primary-50, #F9F5FF);
}
.epHeaderSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    padding: 0 2rem;
}
.epPageHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.epPageHeaderContent{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.epPageHeaderContentText{
    flex: 1 0 0;
    color: #101828;
    font-family: Inter, serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.375rem; /* 126.667% */
}
.epContentSection{
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
}
.epContentContainer{
    display: flex;
    align-items: flex-start;
    gap: 6rem;
    align-self: stretch;
}
.epContentPage{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    border-radius: 1rem;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.epContentPageTitleSection{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
}
.epFeatureIconContainer{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    border: 8px solid var(--Primary-50, #F9F5FF);
    background: var(--Primary-100, #F4EBFF);
}
.epFeatureIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.epContentPageTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    color: var(--Gray-900, #101828);
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.epContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.epContentForm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.epContentRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
}
.epContentRowLabel{
    width: 17.5rem;
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.epImageBlock{
    display: flex;
    width: 32rem;
    justify-content: space-between;
    align-items: center;
}
.epAvatar{
    display: flex;
    width: 7.5rem;
    height: 7.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12.5rem;
}
.epAvatarActionBlock{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.epCCEmailsContainer{
    display: flex;
    width: 32rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.epFormFooterSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.epFormFooter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.epFormFooterActionsBlock{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
}


