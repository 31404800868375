.addProspectiveCustomer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 1rem;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
    margin: 1.5rem 0;
}
.apcHeaderSection{
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
}
.apcTitleBlock{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
}
.apcTitleIconContainer{
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
}
.apcTitleIcon{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}
.apcTitleTextContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
}
.apcTitleText{
    align-self: stretch;
    color: var(--Gray-900, #101828);
    font-family: Inter, serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}
.apcCloseButtonContainer{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.apcContentSection{
    display: flex;
    height: 15.25rem;
    align-items: flex-start;
    align-self: stretch;
}
.apcContentSection1{
    display: flex;
    width: 45rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.apcContentBlock{
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    background: var(--Base-White, #FFF);
}
.apcContentRow{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}
.apcInputFieldSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.apcInputFieldBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
}
.apcInputFieldLabel{
    color: var(--Gray-700, #344054);
    font-family: Inter, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.apcNotesSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
}
.apcNotesContentBlock{
    display: flex;
    padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--Base-White, #FFF);
}
.apcNotesInputFieldSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}
.apcNotesInputFieldBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: 1 0 0;
    align-self: stretch;
}
.apcNotesInputField{
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
}
.apcFooterSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Base-White, #FFF);
}
.apcFooterBlock{
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}
